import React, { useEffect, useState } from 'react';
import academicService from '../../services/academicService';
import periodoService from '../../services/periodoService';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import Loading from '../Loading';


const AcademicPeriodForm = (props) => {
    const { academicPeriod, onClose, ReadOnly }=props;
    const initData={
        academicPeriodId:0,
        name: "",
        order: "",
        startDate: "",
        endDate: "",
        percentage:"",
        hasPercentage:true,
        journeyId: "",
        isActive: false
    };

    const [formData, setFormData] = useState(initData);
    const [journeyList, setJourneyList]=useState([]);
    const [showLoading, setShowLoading] = useState(true);
    
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };
    useEffect(() => {
        const fetchCollections = async () => {
          try {

            const journeyResp = await academicService.getJourneys();
            setJourneyList(journeyResp);
    
            if (academicPeriod) {
              const resPeriodo = await periodoService.getPeriodoAcademico(academicPeriod);
              const checkPercentage = (resPeriodo.percentage!=null);
              setFormData((prevState) => ({ ...prevState, ...resPeriodo, hasPercentage:checkPercentage }));
            }
          } catch (error) {
            console.error("Error al obtener listas de formulario:", error);
          }
          setShowLoading(false);
        };
    
        fetchCollections();
      }, [academicPeriod]);
    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validación básica
        const requiredFields = ['name', 'order', 'startDate', 'endDate', 'journeyId'];
        if(formData.hasPercentage){
          requiredFields.push("percentage");
        }
        for (let field of requiredFields) {
            if (!formData[field]) {
                toast.error("Por favor, completa todos los campos obligatorios.", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                  });
                return;
            }
        }

        if(!ReadOnly)
        {
          setShowLoading(true);
          try {
              //const response = await axios.post('https://tuapi.com/anio_escolar', formData);
              let periodId = 0;
              if (formData.academicPeriodId > 0) {
                const response = await periodoService.updatePeriodoAcademico(formData);
                periodId = response.academicPeriodId;
              } else{
                  const response = await periodoService.createPeriodoAcademico(formData);
                  periodId = response.academicPeriodId;
              }
              if (periodId) {
                  Swal.fire(
                      "Felicidades!",
                      "Información guardada con éxito!",
                      "success",
                      );
                    if (onClose) onClose();
                    setFormData(initData);
              } else {
                  Swal.fire(
                      "Ha ocurrido un error",
                      "Por favor intentelo mas tarde",
                      "error"
                    );
              }
          } catch (error) {
              console.error('Error al enviar el formulario:', error);
              Swal.fire(
                  "Ha ocurrido un error",
                  "Por favor intentelo mas tarde",
                  "error"
                );
          }
        }
        setShowLoading(false);
    };

    return (
         <>
        {showLoading && <Loading />}

        {!showLoading && (
            <div className="container-fluid mt-4">
            <p className="text-muted">Por favor, llena todos los campos para registrar un nuevo período académico.</p>
            
            <form onSubmit={handleSubmit} className="mt-3">
                <div className="row">
        <div className="col-md-6 mb-3">
            <label htmlFor="name" className="form-label">Nombre</label>
            <input type="text" readOnly={ReadOnly} className="form-control" id="name" name="name" placeholder="Ingrese el nombre del periodo" value={formData.name} onChange={handleChange} required />
        </div>

        <div className="col-md-6 mb-3">
            <label htmlFor="order" className="form-label">Orden</label>
            <input type="text" readOnly={ReadOnly} className="form-control" id="order" name="order" placeholder="Ingrese el orden" value={formData.order} onChange={handleChange} required />
        </div>

        <div className="col-md-6 mb-3">
            <label htmlFor="startDate" className="form-label">Fecha de Inicio</label>
            <input type="date" readOnly={ReadOnly} className="form-control" id="startDate" name="startDate" placeholder="Seleccione la fecha de inicio" value={formData.startDate} onChange={handleChange} required />
        </div>
          
        <div className="col-md-6 mb-3">
            <label htmlFor="endDate" className="form-label">Fecha de Cierre</label>
            <input type="date" readOnly={ReadOnly} className="form-control" id="endDate" name="endDate" placeholder="Seleccione la fecha de cierre" value={formData.endDate} onChange={handleChange} required />
        </div>
        
        <div className="col-md-6 mb-3">
        <label className="form-label">Jornada*:</label>
                      <select
                        id="cmbJourney"
                        className="form-control form-select validarForm"
                        name="journeyId"
                        value={formData.journeyId}
                        onChange={handleChange}
                        readOnly={ReadOnly}
                        required
                      >
                        <option value="" disabled={ReadOnly}>
                          Seleccione
                        </option>
                        {journeyList.map((option) => (
                          <option
                            key={option.journeyId}
                            value={option.journeyId}
                            disabled={ReadOnly}
                          >
                            {option.name}
                          </option>
                        ))}
                      </select>
        </div>

        {(formData.hasPercentage && (<>
        <div className="col-md-6 mb-3">
            <label htmlFor="percentage" className="form-label">Porcentaje</label>
            <input type="number" readOnly={ReadOnly} className="form-control" id="percentage" name="percentage" placeholder="Ingrese porcentaje" value={formData.percentage} onChange={handleChange} required />
        </div>
        </>))}

        <div className={(formData.hasPercentage)?"col-md-6 mb-6 padding-top-20 padding-left-10":"col-md-6 mt-5 padding-top-20 padding-left-10"}>
            <input type="checkbox" disabled={ReadOnly} className="form-check-input" id="isActive" name="isActive" checked={formData.isActive} onChange={e => setFormData({...formData, isActive: e.target.checked})} />
            <label className="form-check-label padding-left-5" htmlFor="isActive"> Activo</label>
        </div>

        <div className={(formData.hasPercentage)?"col-md-6 mb-6 padding-top-20 padding-left-10":"col-md-6 mb-6 padding-top-20 padding-left-10"} >
            <input type="checkbox" disabled={ReadOnly} className="form-check-input" id="hasPercentage" name="hasPercentage" checked={formData.hasPercentage} onChange={e => {
              
              if(!e.target.checked){
                setFormData({...formData, hasPercentage: e.target.checked, percentage:null})
              }else{
                setFormData({...formData, hasPercentage: e.target.checked})
              }
              }} />
            <label className="form-check-label padding-left-5" htmlFor="hasPercentage" > Activar porcentaje</label>
        </div>
    </div>

                
                {!ReadOnly  && (
                    <div className='row'>
                        <div className='col-md-1 center padding-top-20 padding-bottom-20'>
                            <button type="submit" className="btn btn-primary">Guardar</button>
                        </div>
                    </div>
                )}
            </form>
            </div>
        )}
        </>
    );
}

export default AcademicPeriodForm;
