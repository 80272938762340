import React, { useRef, useState } from "react";
import { Button } from "react-bootstrap";
import asistenciaService from "../../../services/asistenciaService";
import moment from "moment";
import utils from '../../../utils/generalFunctions';
import academicService from "../../../services/academicService";
import { toast } from 'react-toastify';
import Loading from "../../../components/Loading";
import JornadaAnioPeriodoNivelCurso from "../../../components/Filters/JornadaAnioPeriodoNivelCurso";
import DataTable from "react-data-table-component";
import useCustomDataTableStyles from "../../../components/estilosDeDataTable";
import evaluativeScaleService from "../../../services/evaluativeScaleService";
import observationNoteService from "../../../services/observationNoteService";
import RepoObservacionesRepo from "./observacionesReport";
import RepoObservacionesActa from "./observacionesActa";

const RepoObservaciones = () => {
    const filteReference = useRef(null);
    const initData ={
        schoolYearId: "0",
        journeyId:"0",
        periodId:"",
        educationLevelId: "0",
    };
    const [formData, setFormData] = useState(initData);
    const [asistencias, setAsistencias] = useState([]);
    const [subjects, setSubjects] = useState([]);
    const [datosBusqueda, setDatosBusqueda] = useState([]);
    const [periodos, setPeriodosList] = useState([]);
    const [showLoading, setShowLoading] = useState(false);
    const [estudiantes, setEstudiantes] = useState([]);    
    const [evaluationScale, setEvaluationScale] = useState([]);
    const [filterText, setFilterText] = useState('');
    const [observadorItem, setObservadorItem] = useState(null);
    const [actaPreventivo, setActaPreventivo] = useState(null);
    const [informeActa, setInformeActa] = useState(null);
    

    const updateForm = async (data) => {
        if (data.schoolYearId!==formData.schoolYearId){   
          await cargarPeriodos(data.schoolYearId);
        }
        setFormData(prevState => ({
            ...prevState,
            ...data
          }));
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const cargarPeriodos = async (schoolYearId) => {
        try {
            const schoolYear = await academicService.getYearById(schoolYearId);              
            if(schoolYear){
                setPeriodosList(schoolYear.periods)
            }            
        } catch (error) {
             console.error("Error al obtener información de año escolar:", error);
        }
      }

    const generarCosolidadReporteTotal = async () =>{
        const requiredFields = ['schoolYearId', 'journeyId', 'periodId','educationLevelId','courseId'];
        for (let field of requiredFields) {
            if (!formData[field] || formData[field]==='0') {
                toast.error("Por favor, completa todos los campos obligatorios.", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                  });
                return;
            }
        }
        setDatosBusqueda([]);
        setShowLoading(true);
        setObservadorItem(null);
        setActaPreventivo(null);
        setInformeActa(null);
        try {
           const copyData = utils.copy(formData);
           copyData.periodName = periodos.find(p=>String(p.academicPeriodId)===String(copyData.periodId))?.periodName??"";
           const years = filteReference.current?.getSchoolYears();
           if(years){
                copyData.year = years.find(p=>String(p.schoolYearId)===String(copyData.schoolYearId))?.year??"";
           }
           const journeys = filteReference.current?.getJourneys();
           if(journeys){
                copyData.journey = journeys.find(p=>String(p.journeyId)===String(copyData.journeyId))?.name??"";
           }

           const courses = filteReference.current?.getCursos();
           if(courses){
                copyData.course = courses.find(p=>String(p.courseId)===copyData.courseId)?.name??"";
           }

           const educationLevels = filteReference?.current?.getEducationLeves();
           if (educationLevels.length > 0) {
                copyData.evaluationTypeId = educationLevels.find((c) => String(c.educationLevelId) === String(copyData.educationLevelId))?.evaluationTypeId;
           }

          const evaluationScaleResult = await evaluativeScaleService.getEvaluativeScale(
                copyData.evaluationTypeId,
                copyData.schoolYearId
          );
          setEvaluationScale(evaluationScaleResult);

           setDatosBusqueda(copyData);
           const date = moment(formData.startDate).format("yyyy-MM-DD");
           const data = await observationNoteService.getObservationNotasCourseReport(
            "",
            copyData.courseId,
            copyData.schoolYearId,
            copyData.periodId
          ); // Obtenemos los estudiantes de tu sistema
          console.log(data);
          if(data && data.length>0){
            setEstudiantes(data);
          }else{
            toast.error("No se encontraron estudiantes matriculados.", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
              setEstudiantes([]); 
          }
          
        } catch (error) {
          console.error("Error al obtener estudiantes:", error);
        }
        setShowLoading(false);
    }

    const volverLista =  async () => {
        setObservadorItem(null);
        setActaPreventivo(null);
        setInformeActa(null);
    };

    const generarInforme =  async (student) => {
          setShowLoading(true);
          setObservadorItem(student);
          setActaPreventivo(null);
          setInformeActa(null);
          setShowLoading(false);
    };

    const generarActa =  async (student) => {
      setShowLoading(true);
      setActaPreventivo(student);
      setObservadorItem(null);
      setInformeActa(null);
      setShowLoading(false);
    };

    const evaluationExecuted = (evaluationProcess) => {
        const evaluation = evaluationProcess?.filter(e=> e.evaluativeScaleId!==null);
        return evaluation?.length > 0;
      }

    const columns = [
        { name: "Número", selector: "consecutive", sortable: true, 
        cell: (row, index) => <span>{index + 1}</span>, },      
        { name: "ID", selector: "student.identification", sortable:true,grow:"2.5",},
        { name: "Nombre completo", selector: "student.studentFullName", sortable:true,grow:"6"}, // Asumiendo que 'nombre' contiene tanto el nombre como el apellido
        {
          name: "Acciones",grow:"3",
          selector: "actions",
          cell: (row) => (
          (evaluationExecuted(row.evaluationProcess)) && (
           <div className="actions-container justify-content-between align-items-center">
                <button
                    onClick={() => generarInforme(row)}
                    className="btn-deactivate btn-sm ms-2"
                    title="Exportar">
                    <i class="fa-solid fa-file-pdf"></i>
                </button>                
            </div>
            )
          ),
        },
      ];

    const normalizeString = (str) => {
        return str
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toLowerCase();
    };

    const filteredItems = estudiantes?.filter((item) => {
        if (item) {
          const allData = `${item.fullName} ${item.identification} `;
          const normalizedFilterText = normalizeString(filterText);
          const normalizedItemName = normalizeString(allData);
          return normalizedItemName.includes(normalizedFilterText);
        }
        return item;
      });
    
    const customStyles = useCustomDataTableStyles();


    return (
        <>
          <div className="student-table-container p-3 filter-container">
            <h1>Observador</h1>
            <p>Selecciona los filtros para generar el reporte.</p>
            
                <div className="row">
                    <JornadaAnioPeriodoNivelCurso
                                callback={updateForm}
                                ref={filteReference}
                                loadTutoria={true}
                                loadCoordinators={true}
                                hideAsignatura={true}
                                properties={
                                    {
                                        className:'col-md-2 mb-2',
                                        id:'formReportObservador',
                                        filtro:false
                                    }
                                }
                            />
                        <div className="col-md-2 mb-1 margin-top-45">
                                <Button onClick={generarCosolidadReporteTotal} className="ml-2">Buscar</Button>
                        </div>
                </div>
          </div>
          {showLoading && <Loading />}

          {!showLoading && estudiantes.length>0 && !observadorItem && !actaPreventivo && !informeActa && (
            <>
             <div className="student-table-container p-3 mt-3">
              <div className="student-table-controls mb-3 d-flex justify-content-between align-items-center">
                <input
                    type="text"
                    placeholder="Buscar estudiante"
                    value={filterText}
                    onChange={e => setFilterText(e.target.value)}
                    className="form-control form-control-sm w-50"
                />
                </div>
                <DataTable
                title="Lista de estudiantes"
                className="students-datatable shadow-sm rounded"
                columns={columns}
                // actions={actionsMemo}
                noDataComponent="No hay información que mostrar, por favor añadela"   
                data={filteredItems}
                pagination
                fixedHeader
                customStyles={customStyles}
                paginationPerPage={50} // Agrega esta propiedad para controlar las filas por página
                paginationRowsPerPageOptions={[50, 100]}

            />
            </div>
            </>
          )}

            {!showLoading && observadorItem && (
                  <div>
                  <button className="btn btn-secondary"  onClick={()=>{volverLista()}}><i className="fas fa-arrow-left"></i>Volver a lista</button>
                   <RepoObservacionesRepo
                    reporte={observadorItem}
                  /> 
                </div>
            )}

            {!showLoading && actaPreventivo && (
                  <div>
                  <button className="btn btn-secondary"  onClick={()=>{volverLista()}}><i className="fas fa-arrow-left"></i>Volver a lista</button>
                   <RepoObservacionesActa
                    reporte={actaPreventivo}
                  /> 
                </div>
            )}
        </>
    );
};
  
export default RepoObservaciones;