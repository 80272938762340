import React, { useEffect, useState } from 'react';
import evaluativeScaleService from '../../services/evaluativeScaleService';
import utils from '../../utils/generalFunctions';
import types from '../../services/types';
import docentesService from '../../services/docentesService';
import Swal from 'sweetalert2';
import observationNoteService from '../../services/observationNoteService';
import Loading from '../Loading';

const EvaluationForm = ({selectedStudent, readOnly, onClose}) => {
  const [key, setKey] = useState('academico');
  const [evaluationScale, setEvaluationScale] = useState([]);
  const [evaluationArea, setEvaluationArea] = useState([]);  
  const [selectValues, setSelectValues] = useState({}); // Estado para los valores de los selects
  const [showLoading, setShowLoading] = useState(false);
  const user = utils.getUserInformation();
  
  const handleAcademicChange = (id, value) => {
    setSelectValues(prev => ({ ...prev, [id]: value })); // Actualiza el valor del select específico
    const evaluationProcess = selectedStudent.evaluationProcess.find(e=> e.evaluationProcessId===id);
    if(evaluationProcess){
      evaluationProcess.evaluativeScaleId = value;
    }
  };

  // Función para manejar el envío de los formularios
  const handleSubmit = async event  =>  {
    event.preventDefault();
    setShowLoading(true);
    try{
        let observations = [];
        const student = selectedStudent.student;
        for(let pro of selectedStudent.evaluationProcess) {
          const observation = {
            observationEvaluateId: pro.observationEvaluateId??0,
            schoolSettingId: student.schoolSettingId,
            studentId: student.studentId,
            teacherId: student.groupDirectorId,
            evaluationProcessId: pro.evaluationProcessId,
            evaluativeScaleId: pro.evaluativeScaleId,
            schoolYearId:student.schoolYearId,
            periodId:student.periodId,
            createdUser:user.userId
          }
          observations.push(observation);
        }
        const data = await observationNoteService.createObservationNotaListAcademico(observations);
        if(data.message==="Observaciones guardadas")
        {
          Swal.fire("Felicidades!", "Observación guardada con éxito!", "success");
          if(onClose) onClose(true);
        }else{
          Swal.fire("Ha ocurrido un error", "Por favor intentelo mas tarde", "error");
        }
      }catch(ex){
        console.log(ex);
        Swal.fire("Ha ocurrido un error", "Por favor intentelo mas tarde", "error");
      }
    setShowLoading(false);
  };

  const cargarEscalaValorativa = async () => {

    const evaluationScale = await evaluativeScaleService.getEvaluativeScale(types.evaluationTypes.PLANILLA_PREVENTIVA,0);
    setEvaluationScale(evaluationScale);
    const evaluationProcess = selectedStudent.evaluationProcess;
    const areas =  utils.removeDuplicates((evaluationProcess.map((ep) =>  { return {name:ep.evaluationType, id:ep.evaluationTypeId}})));
    setEvaluationArea(areas);
    evaluationProcess.map(m=> {
        if(!m.evaluativeScaleId){
          m.evaluativeScaleId = getEvaluationScaleDefault(evaluationScale);
        }
        return m;
    })
  }

  const getEvaluationScaleDefault = (evaluationScale) => {
    const evaluationDefault = evaluationScale.find(e=>e.name==="Generalmente");
    return (evaluationDefault)?evaluationDefault.evaluativeScaleId:null;
  }

  const cargarProcesos = async () => {
    const initialSelectValues = {};
    const evaluationScale = await evaluativeScaleService.getEvaluativeScale(types.evaluationTypes.PLANILLA_PREVENTIVA,0);
    selectedStudent.evaluationProcess.forEach(ep => {
      initialSelectValues[ep.evaluationProcessId] = ep.evaluativeScaleId || getEvaluationScaleDefault(evaluationScale);
    });
    setSelectValues(initialSelectValues);
  }
  
  useEffect(() => {
      cargarEscalaValorativa();
  }, []);

  useEffect(() => {
    // Cargar datos iniciales
    cargarProcesos();
  }, [selectedStudent]);

  return (
    <>
        {showLoading && <Loading />}

          {!showLoading && (

              <form onSubmit={handleSubmit}>
              {evaluationArea.map((ev) => (
                <>
                    <h4>{ev.name}</h4>
                    <table className='table table-bordered table-sm table-striped my-0' >
                          <tr>
                            <th> Proceso </th>
                            <th> Valor </th>
                          </tr>
                    <tbody className='margin-bottom-10'>
                    {selectedStudent?.evaluationProcess.filter(e=>e.evaluationTypeId === ev.id).map((ep) => (
                      <tr>              
                        <td><label htmlFor={`academic-${ep.evaluationProcessId}`}>{ep.evaluationProcess}.</label></td>
                            <td>
                              <select
                                className="form-select"
                                id={`academic-${ep.evaluationProcessId}`}
                                value={selectValues[ep.evaluationProcessId] || ''}
                                onChange={(e) => handleAcademicChange(ep.evaluationProcessId, e.target.value)}
                                readOnly={readOnly}
                              >
                                {evaluationScale.map((es) => (
                                  <option value={es.evaluativeScaleId} disabled={readOnly}>{es.name}</option>
                                ))}
                              </select>
                        </td>
                      </tr>
                    ))}
                    </tbody>
                    </table>
                    <br/>
                  </>
              ))}

                {!readOnly && (
                   <div className='row'>
                      <div className='col-md-12 text-center'>
                          <button type="submit" className="btn btn-primary">Guardar</button>
                      </div>
                  </div>
                )}
            </form>
        )}
    </>
  );
};

export default EvaluationForm;
