import api from './api';

const getEvaluativeScale = async (evaluationTypeId, schoolYearId) => {
    try {
        const response = await api.get(`academic/evaluative-scale/${evaluationTypeId}?schoolYearId=${schoolYearId}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export default {
    getEvaluativeScale
};